import { NgModule } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BlockUIModule } from 'primeng/blockui';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';

const PRIMENGMODULES = [ButtonModule, ToastModule, InputTextModule, DropdownModule, BlockUIModule, ProgressSpinnerModule];

@NgModule({
  declarations: [],
  exports: PRIMENGMODULES,
  providers: [MessageService],
})
export class PrimeNgModule {}
