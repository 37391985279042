<kiosk-wizard [step]="2">
  <div class="kiosk-hw100">
    <div class="kiosk-center-text mb-4">
      <h1 class="kiosk-sub-title" transloco="app.components.wizard.steps.scan-badge-success.title"></h1>
      <h2 class="kiosk-sub-title" transloco="app.components.wizard.steps.scan-badge-success.sub-title"></h2>
    </div>

    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <kiosk-readonly label="app.components.wizard.steps.scan-badge-success.name" [value]="driverName"></kiosk-readonly>
      </div>
      <div class="col-4"></div>
    </div>

    <div class="row">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="c-scan-badge-success-buttons">
          <button
            pButton
            pRipple
            type="button"
            label="{{'app.generic.no' | transloco}}"
            (click)="navigateNo()"
            class="p-button-danger btn-xl"
          ></button>
          <button
            pButton
            pRipple
            type="button"
            label="{{'app.generic.yes' | transloco}}"
            (click)="navigate()"
            class="p-button-success btn-xl"
          ></button>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</kiosk-wizard>
