<div id="dev-component" class="kiosk-appear">
  <h2 title="click to toggle body" (click)="showBody = !showBody">Fake Scanner:</h2>
  <div *ngIf="showBody">
    <input type="text" pInputText [(ngModel)]="inputBadgeNumber" class="mx-2" />
    <button pButton (click)="fakeScanBadgeSuccess()" label="ScanBadgeSuccess"></button>
    <button pButton (click)="fakeScanBadgeError()" class="p-button-danger" label="ScanBadgeError"></button>

    <input type="text" pInputText [(ngModel)]="scannedAppointmentId" class="mx-2" />
    <button pButton (click)="fakeScanQrCodeSucess(scannedAppointmentId)" label="ScanQrSuccess"></button>
    <button pButton (click)="fakeScanQrCodeError()" label="ScanQrError" class="p-button-danger"></button>

    <button pButton (click)="init()" class="p-button-success" label="initialize"></button>
    <div class="w-100 pl-3" *ngIf="scannedBadgeInfo">
      <div>
        <span>Badge number: {{scannedBadgeInfo.badgeNumber}}</span>
      </div>
      <div class="ml-4">
        <span>AppointmentId: {{scannedBadgeInfo.appointmentId}}</span>
      </div>
      <div class="ml-4">
        <span>DriverId: {{scannedBadgeInfo.driverId}}</span>
      </div>
    </div>

    <input type="text" pInputText [(ngModel)]="scannedAppointmentBolId" class="mx-2" />

    <button pButton (click)="printBol()" label="Print BOL" class="p-button-success"></button>
    <p-dropdown [options]="routes" (onChange)="navigate($event)" placeholder="navigate to (experimental)"></p-dropdown>
  </div>
</div>
