import { Component } from '@angular/core';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-scan-qr-code-success',
  templateUrl: './scan-qr-code-success.component.html',
})
export class ScanQrCodeSuccessComponent {
  constructor(private readonly wizard: WizardController) {}

  navigate(): void {
    this.wizard.notifyConfirmDetails();
  }
}
