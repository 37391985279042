import { Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SettingsService } from 'src/app/kiosk/services/settings.service';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@UntilDestroy()
@Component({
  selector: 'kiosk-scan-badge-already-active',
  templateUrl: './scan-badge-already-active.component.html',
})
export class ScanBadgeAlreadyActiveComponent implements OnInit {
  constructor(private readonly wizard: WizardController, private readonly settingService: SettingsService) {}

  ngOnInit(): void {
    this.wizard.notifyCheckoutCompleted(this.settingService.navigationDelay);
  }
}
