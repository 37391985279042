<div class="c-welcome-logo kiosk-appear">
  <img class="c-welcome-logo-title" src="assets/graphics/svg/fluxys-title.svg" alt="" />
  <img class="c-welcome-logo-logo" src="assets/graphics/svg/fluxys-logo.svg" alt="" />
</div>

<div class="c-welcome-layout-container kiosk-full-screen">
  <div class="c-welcome-card-container">
    <kiosk-welcom-card locale="en" (languageSelected)="selectActiveLanguage($event)"></kiosk-welcom-card>
    <kiosk-welcom-card locale="nl" (languageSelected)="selectActiveLanguage($event)"></kiosk-welcom-card>
    <kiosk-welcom-card locale="de" (languageSelected)="selectActiveLanguage($event)"></kiosk-welcom-card>
    <kiosk-welcom-card locale="fr" (languageSelected)="selectActiveLanguage($event)"></kiosk-welcom-card>
  </div>
</div>
