import { Component } from '@angular/core';
import { SettingsService } from 'src/app/kiosk/services/settings.service';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-scan-badge-error',
  templateUrl: './scan-badge-error.component.html',
})
export class ScanBadgeErrorComponent {
  constructor(private readonly wizard: WizardController, private readonly settingService: SettingsService) {
    this.wizard.notifyScanBadge(this.settingService.navigationDelay);
  }
  navigate(): void {
    this.wizard.notifyScanBadge();
  }
}
