import { PlatformLocation } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  readonly isDebugMode: boolean | undefined;

  // default delay in ms before being automatically redirected to another page.
  private readonly defaultNavigationDelay = 10 * 1000;
  // default time in milliseconds before resetting the context and redirection to home page
  // in case of inactivity
  private readonly defaultSessionTimeOut = 60 * 1000;

  constructor(private readonly platformLocation: PlatformLocation) {
    this.isDebugMode = this.isLocalhostOrDevEnvironment();
  }

  get navigationDelay(): number {
    return this.isDebugMode === true ? 1000 * 1000 : this.defaultNavigationDelay;
  }

  get sessionTimeOut(): number {
    return this.isDebugMode === true ? 1000 * 1000 : this.defaultSessionTimeOut;
  }

  get skipScanEventTime(): number {
    return 5 * 1000;
  }

  get printerShield(): number {
    return 20 * 1000;
  }

  // ref: https://stackoverflow.com/a/57949518
  private isLocalhostOrDevEnvironment(): boolean {
    const hostName = this.platformLocation.hostname;

    return (
      hostName === '127.0.0.1' ||
      hostName === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      hostName === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      !!hostName.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/) ||
      // DEV environment
      hostName.startsWith('kioskweb-truckmanager.service.consul.dev.flbe.fluxys.com')
    );
  }
}
