import { Component } from '@angular/core';
import { KioskContext } from 'src/app/kiosk/models/kiosk-context';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-printing-spinner',
  templateUrl: './printing-spinner.component.html',
  styleUrls: ['./printing-spinner.component.scss'],
})
export class PrintingSpinnerComponent {
  context: KioskContext;
  constructor(wizardController: WizardController) {
    this.context = wizardController.context;
  }
}
