import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-confirm-appointment-wrong-details',
  templateUrl: './confirm-appointment-wrong-details.component.html',
  styleUrls: ['./confirm-appointment-wrong-details.component.scss'],
})
export class ConfirmAppointmentWrongDetailsComponent {
  errors: string[] = [];
  constructor(private readonly wizard: WizardController, transloco: TranslocoService) {
    const errorCodes = this.wizard.context.getAppointment().errors;
    if (errorCodes.length > 0) {
      errorCodes.forEach((code) => {
        this.errors.push(transloco.translate(`app.components.wizard.steps.confirm-appointment-wrong-details.errors.${code}`));
      });
    }
  }
  navigate(): void {
    this.wizard.notifyScanQrCode();
  }
}
