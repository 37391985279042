import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'kiosk-welcom-card',
  templateUrl: './welcom-card.component.html',
  styleUrls: ['./welcom-card.component.scss'],
})
export class WelcomCardComponent implements OnInit {
  @Input() locale = '';
  @Output() readonly languageSelected = new EventEmitter<string>();

  subtitle = '';
  content = '';

  constructor(private readonly transloco: TranslocoService) {}

  ngOnInit(): void {
    this.transloco.selectTranslate('app.components.welcome-card.title', [], this.locale).subscribe((subtitle: string) => {
      this.subtitle = subtitle;
    });
    this.transloco.selectTranslate('app.components.welcome-card.content', [], this.locale).subscribe((content: string) => {
      this.content = content;
    });
  }

  selectLanguage(): void {
    this.languageSelected.emit(this.locale);
  }
}
