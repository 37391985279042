/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { DebugService } from 'src/app/kiosk/services/debug.service';
import { ScannerService } from 'src/app/kiosk/services/scanner.service';
import { WizardController as WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-dev',
  templateUrl: './dev.component.html',
  styleUrls: ['./dev.component.scss'],
})
export class DevComponent {
  scannedAppointmentId = 0;
  inputBadgeNumber = '0';
  scannedAppointmentBolId = 0;
  showBody = true;
  routes: SelectItem[] = [];

  constructor(
    private readonly router: Router,
    private readonly scanner: ScannerService,
    private readonly wizard: WizardController,
    private readonly debug: DebugService
  ) {
    this.routes = [];
    router.config
      .sort((a, b) => {
        const astr = a.path;
        const bstr = b.path;

        return astr! < bstr! ? -1 : 1; // naive, I know
      })
      .forEach((r) => {
        this.routes.push({ label: r.path, value: r.path });
      });
  }

  get scannedBadgeInfo(): {
    badgeNumber: number;
    appointmentId: number | null | undefined;
    driverId: number | null | undefined;
  } | null {
    try {
      const badge = this.wizard.context.getBadge();

      return { badgeNumber: badge.badgeNumber, appointmentId: badge.badgeLoan?.appointmentId, driverId: badge.badgeLoan?.driverId };
    } catch (e) {
      return null;
    }
  }

  fakeScanBadgeSuccess(): void {
    this.scanner.scanBadgeSuccess(this.inputBadgeNumber);
  }

  fakeScanBadgeError(): void {
    this.scanner.scanBadgeError();
  }

  fakeScanQrCodeSucess(appointmentId: number): void {
    // call debugService to get appointmentToken
    this.debug.getAppointmentToken(appointmentId).subscribe((token: string) => this.scanner.scanQrCodeSuccess(token));
  }

  fakeScanQrCodeError(): void {
    this.scanner.scanQrCodeError();
  }

  printBol(): void {
    this.wizard.notifyDebugPrintBillOfLadingComplete(this.scannedAppointmentBolId);
  }

  init(): void {
    this.wizard.notifyInitial();
  }

  navigate(event: { value: string }): void {
    // tsignore
    void this.router.navigate([`/${event.value}`]);
  }
}
