<kiosk-wizard [step]="6">
  <div class="kiosk-hw100">
    <h1 class="text-center">{{ 'app.components.wizard.steps.complete-checkout.title' | transloco }}</h1>
    <h2 class="kiosk-sub-title text-center mb-4">{{ 'app.components.wizard.steps.complete-checkout.sub-title' | transloco }}</h2>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-around">
      <button
        pButton
        (click)="onFinishButtonClicked()"
        label="{{'app.components.wizard.steps.complete-checkout.ok' | transloco}}"
        class="btn-xl"
      ></button>
    </div>
  </div>
</kiosk-wizard>
