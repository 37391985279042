import { Component } from '@angular/core';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-scan-qr-code-error',
  templateUrl: './scan-qr-code-error.component.html',
})
export class ScanQrCodeErrorComponent {
  constructor(private readonly wizard: WizardController) {}
  navigate(): void {
    this.wizard.notifyScanQrCode();
  }
}
