/* eslint-disable @typescript-eslint/naming-convention */
export enum AppointmentStatus {
  Reserved = 'Reserved',
  Booked = 'Booked',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Rejected = 'Rejected',
  Confirmed = 'Confirmed',
  Denied = 'Denied',
  Arrived = 'Arrived',
  Operational = 'Operational',
  LoadingCompleted = 'LoadingCompleted',
}

export enum BookedServiceCodes {
  TruckAuthorizationService = 'TruckAuthorizationService',
  TruckCoolingDownService = 'TruckCoolingDownService',
  AssistedTruckLoadingService = 'AssistedTruckLoadingService',
  SelfTruckLoadingService = 'SelfTruckLoadingService',
}
