/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
import { AppointmentViewModel } from './appointment.viewmodel';
import { BadgeViewModel } from './badge.viewmodel';
import { AppointmentStatus } from './enums';

export class KioskContext {
  isPrinting = false;
  private badge?: BadgeViewModel;
  private appointment?: AppointmentViewModel;
  private appointmentToken?: string;

  get badgeLoanHasAppointment(): boolean {
    // ignore the typescript remark: this is working, the optional chain does not.
    return this.badge !== undefined && this.badge.hasBadgeLoan && this.badge.badgeLoan !== null && this.badge.badgeLoan.hasAppointment;
  }

  get badgeLoanAppointmentIsArrived(): boolean {
    return this.badgeLoanHasAppointment && this.appointment?.operationalStatus === AppointmentStatus.Arrived;
  }

  get appointmentStatus(): AppointmentStatus {
    return this.appointment?.operationalStatus as AppointmentStatus;
  }

  get hasAppointment(): boolean {
    return this.appointment !== undefined;
  }

  get billOfLadingId(): number | null | undefined {
    // return 29187;
    return this.appointment?.billOfLadingId;
  }

  get flowIsCheckin(): boolean | undefined {
    return this.badge?.flowIsCheckIn;
  }

  get isDebugMode(): boolean {
    const debugMode = localStorage.getItem('debugMode');
    return debugMode === 'true' ? true : false;
  }

  addBadge(badge: BadgeViewModel): void {
    console.debug('badge:');
    console.debug(badge);
    this.badge = badge;
  }

  addAppointment(appointment: AppointmentViewModel): void {
    console.debug('appointment');
    console.debug(appointment);
    this.appointment = appointment;
  }

  addAppointmentToken(token: string): void {
    this.appointmentToken = token;
  }

  getAppointment(): AppointmentViewModel {
    if (!this.appointment) throw Error('no appointment present');
    return this.appointment;
  }

  getAppointmentToken(): string {
    if (!this.appointmentToken) {
      throw Error('no appointment token present');
    }
    return this.appointmentToken;
  }

  getBadge(): BadgeViewModel {
    if (!this.badge) {
      throw Error('no badge specified');
    }
    return this.badge;
  }

  addErrorCode(code: string): void {
    if (this.appointment) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!this.appointment.errors) {
        this.appointment.errors = [];
      }
      this.appointment.errors.push(code);
    }
  }
}
