<kiosk-wizard [step]="6">
  <div class="kiosk-hw100">
    <div>
      <h1 class="kiosk-title text-center">{{ 'app.components.wizard.steps.check-in-completed.title' | transloco }}</h1>
      <h2 class="kiosk-sub-title text-center mb-4">{{ 'app.components.wizard.steps.check-in-completed.sub-title' | transloco }}</h2>
    </div>
    <kiosk-template [defaultTemplate]="false">
      <div class="container-fluid">
        <div class="row mb-5">
          <div class="col-6 d-flex align-items-center">
            <h3 class="kiosk-step-title">{{ 'app.components.wizard.steps.check-in-completed.step-1' | transloco }}</h3>
          </div>
          <div class="col-6 d-flex">
            <img src="assets/graphics/png/forbidden-goods.png" class="ml-auto" alt="leave inflammable & electronic goods" />
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-around">
            <i class="fa fa-arrow-down fa-3x icon-arrow-down"></i>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-6 d-flex align-items-center">
            <h3 class="kiosk-step-title">{{ 'app.components.wizard.steps.check-in-completed.step-2' | transloco }}</h3>
          </div>
          <div class="col-6 d-flex">
            <img src="assets/graphics/png/beeper.png" class="mx-auto" alt="wait for the beeper" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col d-flex justify-content-around">
            <i class="fa fa-arrow-down fa-3x icon-arrow-down"></i>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-6 d-flex align-items-center">
            <h3 class="kiosk-step-title">{{ 'app.components.wizard.steps.check-in-completed.step-3' | transloco }}</h3>
          </div>
          <div class="col-6 d-flex">
            <img src="assets/graphics/png/truck-loading.png" class="ml-auto" alt="go for truck operation" />
          </div>
        </div>
      </div>
    </kiosk-template>
  </div>
  <div class="kiosk-bottom-button-container">
    <button
      pButton
      pRipple
      type="button"
      label="{{'app.components.wizard.steps.check-in-completed.end' | transloco}}"
      (click)="navigate()"
      class="btn-xl"
    ></button>
  </div>
</kiosk-wizard>
