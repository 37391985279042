import { Component, Input } from '@angular/core';

import { SettingsService } from '../../services/settings.service';
import { WizardController } from '../../services/wizard-controller.service';

@Component({
  selector: 'kiosk-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent {
  @Input() step = 0;
  constructor(private readonly wizard: WizardController, private readonly settingsService: SettingsService) {}

  get isDebugMode(): boolean {
    return this.wizard.context.isDebugMode && this.settingsService.isDebugMode === true;
  }

  get flowIsCheckIn(): boolean | undefined {
    return this.wizard.flowIsCheckIn;
  }

  back(): void {
    this.wizard.notifyInitial();
  }

  help(): void {
    alert('replace with some help content');
  }
}
