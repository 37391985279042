import { Component, Input } from '@angular/core';

@Component({
  selector: 'kiosk-necklace',
  templateUrl: './necklace.component.html',
  styleUrls: ['./necklace.component.scss'],
})
export class NecklaceComponent {
  @Input() step = 0;
}
