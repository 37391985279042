<div *ngIf="context.isPrinting">
  <p-blockUI [blocked]="true">
    <div class="c-printer-spinner-container">
      <i class="c-printer-spinner-icon pi pi-print"></i>
      <div class="c-printer-dot-container">
        <div class="c-printer-dot"></div>
        <div class="c-printer-dot"></div>
        <div class="c-printer-dot"></div>
      </div>
    </div>
  </p-blockUI>
</div>
