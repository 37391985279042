export const KIOSK_ROUTES = {
  initial: '',
  welcome: 'welcome',
  somethingWentWrong: 'somethingwentwrong',
  checkin: {
    scanBadge: 'scanbadge',
    scanBadgeSuccess: 'scanbadgesuccess',
    scanBadgeError: 'scanbadgeerror',
    scanBadgeNoDriverLinked: 'scanbadgenodriverlinked',
    scanQrCode: 'scanqrcode',
    scanQrCodeSuccess: 'scanqrcodesuccess',
    scanQrCodeError: 'scanqrcodeerror',
    scanQrCodeNoAppointment: 'scanqrcodenoappointment',
    confirm: 'confirm',
    confirmWrongDetails: 'confirmwrongdetails',
    checkinComplete: 'checkincomplete',
  },
  checkout: {
    scanBadgeAlreadyActive: 'scanbadgealreadyactive',
    printBol: 'printbol',
    confirmPrint: 'confirmprint',
    checkoutComplete: 'checkoutcomplete',
  },
};
