import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  onScanBadgeSuccess: Subject<string> = new Subject<string>();
  onScanBadgeError: Subject<unknown> = new Subject<unknown>();
  onScanQrCodeSucess: Subject<string> = new Subject<string>();
  onScanQrCodeError: Subject<unknown> = new Subject<unknown>();

  scanBadgeSuccess(badgeNumber: string): void {
    this.onScanBadgeSuccess.next(badgeNumber);
  }

  // TODO: not used except in DEV component
  scanBadgeError(): void {
    this.onScanBadgeError.next('');
  }

  scanQrCodeSuccess(appointmentToken: string): void {
    this.onScanQrCodeSucess.next(appointmentToken);
  }

  // TODO: not used except in DEV component
  scanQrCodeError(): void {
    this.onScanQrCodeError.next('');
  }
}
