<kiosk-wizard [step]="3">
  <div class="kiosk-hw100">
    <kiosk-template
      mainTitle="app.components.wizard.steps.print-bol.title"
      subtitle="app.components.wizard.steps.print-bol.sub-title"
      image="print"
    ></kiosk-template>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-around">
      <button pButton (click)="onPrintBolButtonClicked()" label="Print" class="btn-xl"></button>
    </div>
  </div>
</kiosk-wizard>
