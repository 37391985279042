import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KIOSK_ROUTES } from 'src/app-routing';

import { WelcomeComponent } from './kiosk/components/welcome/welcome.component';
import { ScanBadgeErrorComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge-error/scan-badge-error.component';
import { ScanBadgeNoDriveLinkedComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge-no-drive-linked/scan-badge-no-drive-linked.component';
import { ScanBadgeSuccessComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge-success/scan-badge-success.component';
import { ScanBadgeComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge.component';
import { ScanQrCodeErrorComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code-error/scan-qr-code-error.component';
import { ScanQrCodeNoAppointmentScheduledComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code-no-appointment-scheduled/scan-qr-code-no-appointment-scheduled.component';
import { ScanQrCodeSuccessComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code-success/scan-qr-code-success.component';
import { ScanQrCodeComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code.component';
import { ConfirmAppointmentDetailsComponent } from './kiosk/components/wizard/steps/check-in/3.confirm-appointment-details/confirm-appointment-details.component';
import { ConfirmAppointmentWrongDetailsComponent } from './kiosk/components/wizard/steps/check-in/3.confirm-appointment-details/confirm-appointment-wrong-details/confirm-appointment-wrong-details.component';
import { CheckinCompleteComponent } from './kiosk/components/wizard/steps/check-in/4.checkin-complete/checkin-complete.component';
import { ScanBadgeAlreadyActiveComponent } from './kiosk/components/wizard/steps/check-out/1.scan-badge/scan-badge-already-active/scan-badge-already-active.component';
import { PrintBolComponent } from './kiosk/components/wizard/steps/check-out/2.print-bol/print-bol.component';
import { ConfirmPrintComponent } from './kiosk/components/wizard/steps/check-out/3.confirm-print/confirm-print.component';
import { CheckoutCompleteComponent } from './kiosk/components/wizard/steps/check-out/4.checkout-complete/checkout-complete.component';
import { SomethingWentWrongComponent } from './kiosk/components/wizard/steps/something-went-wrong/something-went-wrong.component';

const ROUTES: Routes = [
  {
    path: KIOSK_ROUTES.initial,
    redirectTo: KIOSK_ROUTES.welcome,
    pathMatch: 'full',
    data: { state: KIOSK_ROUTES.initial },
  },
  {
    path: KIOSK_ROUTES.welcome,
    component: WelcomeComponent,
    data: { state: KIOSK_ROUTES.welcome },
  },
  {
    path: KIOSK_ROUTES.somethingWentWrong,
    component: SomethingWentWrongComponent,
    data: { state: KIOSK_ROUTES.somethingWentWrong },
  },
  // checkin
  {
    path: KIOSK_ROUTES.checkin.scanBadge,
    component: ScanBadgeComponent,
    data: { state: KIOSK_ROUTES.checkin.scanBadge },
  },
  {
    path: KIOSK_ROUTES.checkin.scanBadgeSuccess,
    component: ScanBadgeSuccessComponent,
    data: { state: KIOSK_ROUTES.checkin.scanBadgeSuccess },
  },
  {
    path: KIOSK_ROUTES.checkin.scanBadgeError,
    component: ScanBadgeErrorComponent,
    data: { state: KIOSK_ROUTES.checkin.scanBadgeError },
  },
  {
    path: KIOSK_ROUTES.checkin.scanBadgeNoDriverLinked,
    component: ScanBadgeNoDriveLinkedComponent,
    data: { state: KIOSK_ROUTES.checkin.scanBadgeNoDriverLinked },
  },
  {
    path: KIOSK_ROUTES.checkin.scanQrCode,
    component: ScanQrCodeComponent,
    data: { state: KIOSK_ROUTES.checkin.scanQrCode },
  },
  {
    path: KIOSK_ROUTES.checkin.scanQrCodeSuccess,
    component: ScanQrCodeSuccessComponent,
    data: { state: KIOSK_ROUTES.checkin.scanQrCodeSuccess },
  },
  {
    path: KIOSK_ROUTES.checkin.scanQrCodeError,
    component: ScanQrCodeErrorComponent,
    data: { state: KIOSK_ROUTES.checkin.scanQrCodeError },
  },
  {
    path: KIOSK_ROUTES.checkin.scanQrCodeNoAppointment,
    component: ScanQrCodeNoAppointmentScheduledComponent,
    data: { state: KIOSK_ROUTES.checkin.scanQrCodeNoAppointment },
  },
  {
    path: KIOSK_ROUTES.checkin.confirm,
    component: ConfirmAppointmentDetailsComponent,
    data: { state: KIOSK_ROUTES.checkin.confirm },
  },
  {
    path: KIOSK_ROUTES.checkin.confirmWrongDetails,
    component: ConfirmAppointmentWrongDetailsComponent,
    data: { state: KIOSK_ROUTES.checkin.confirmWrongDetails },
  },
  {
    path: KIOSK_ROUTES.checkin.checkinComplete,
    component: CheckinCompleteComponent,
    data: { state: KIOSK_ROUTES.checkin.checkinComplete },
  },
  // checkout
  {
    path: KIOSK_ROUTES.checkout.scanBadgeAlreadyActive,
    component: ScanBadgeAlreadyActiveComponent,
    data: { state: KIOSK_ROUTES.checkout.scanBadgeAlreadyActive },
  },

  {
    path: KIOSK_ROUTES.checkout.printBol,
    component: PrintBolComponent,
    data: { state: KIOSK_ROUTES.checkout.printBol },
  },

  {
    path: KIOSK_ROUTES.checkout.confirmPrint,
    component: ConfirmPrintComponent,
    data: { state: KIOSK_ROUTES.checkout.confirmPrint },
  },

  {
    path: KIOSK_ROUTES.checkout.checkoutComplete,
    component: CheckoutCompleteComponent,
    data: { state: KIOSK_ROUTES.checkout.checkoutComplete },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
