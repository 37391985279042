<div class="kiosk-full-screen">
  <div class="container-fluid">
    <div class="row">
      <!--left column-->
      <div class="col-4 c-wizard-left-bar">
        <div class="c-wizard-left-bar-container">
          <div class="c-wizard-left-bar-label" (click)="back()">
            <i class="far fa-home"></i><span transloco="app.components.wizard.back-to-start"></span>
          </div>

          <div *ngIf="step > 2">
            <kiosk-necklace [step]="step" *ngIf="flowIsCheckIn"></kiosk-necklace>
            <kiosk-necklace-checkout [step]="step" *ngIf="!flowIsCheckIn"></kiosk-necklace-checkout>
          </div>
          <div class="mt-5"></div>
        </div>
      </div>
      <!--right column: content-->
      <div class="col-8 c-wizard-right-content kiosk-appear kiosk-slide-in">
        <p-toast></p-toast>
        <flx-loading-spinner context="kiosk"></flx-loading-spinner>
        <ng-content></ng-content>
        <kiosk-dev *ngIf="isDebugMode"></kiosk-dev>
      </div>
    </div>
  </div>
  <kiosk-printing-spinner></kiosk-printing-spinner>
</div>
<script src="../../../..assets/script/print.js"></script>
