import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { withLoadingSpinner } from '@fluxys/primeng';
import { HttpContextFactory } from '@shared/utils/http.context.factory';
import { Observable } from 'rxjs';

import { AppointmentViewModel } from '../models/appointment.viewmodel';
import { BadgeViewModel } from '../models/badge.viewmodel';

@Injectable({
  providedIn: 'root',
})
export class KioskService {
  private readonly baseUrl = '/api/kiosk';
  private readonly spinnerContext = 'kiosk';
  constructor(private readonly http: HttpClient) {}

  getBadge(badgeNumber: string): Observable<BadgeViewModel> {
    return this.http.get<BadgeViewModel>(`${this.baseUrl}/badge/${badgeNumber}`, {
      context: HttpContextFactory.create(this.spinnerContext),
    });
  }

  getAppointment(appointmentToken: string, driverId: number | undefined): Observable<AppointmentViewModel> {
    return this.http.post<AppointmentViewModel>(
      `${this.baseUrl}/appointment`,
      {
        appointmentToken,
        driverId,
      },
      { context: HttpContextFactory.create(this.spinnerContext) }
    );
  }

  getAppointmentBillOfLading(billOfLadingId: number): Observable<HttpResponse<Blob>> {
    return this.http.get(`${this.baseUrl}/billoflading/${billOfLadingId}`, {
      context: withLoadingSpinner(this.spinnerContext),
      responseType: 'blob',
      observe: 'response',
    });
  }

  testPrint() {
    return this.http.get(`${this.baseUrl}/testprint`, {
      context: withLoadingSpinner(this.spinnerContext),
      responseType: 'blob',
      observe: 'response',
    });
  }

  setAppointmentStatusArrived(appointmentToken: string): Observable<FlxValidatedResult> {
    return this.http.post<FlxValidatedResult>(
      `${this.baseUrl}/settostatusarrived`,
      { appointmentToken },
      { context: HttpContextFactory.create(this.spinnerContext) }
    );
  }

  completeCheckout(badgeNumber: number): Observable<FlxValidatedResult> {
    return this.http.post<FlxValidatedResult>(
      `${this.baseUrl}/completecheckout`,
      { badgeNumber },
      { context: HttpContextFactory.create(this.spinnerContext) }
    );
  }

  confirmDetails(badgeNumber: number, appointmentToken: string): Observable<FlxValidatedResult> {
    return this.http.post<FlxValidatedResult>(
      `${this.baseUrl}/confirmdetails`,
      { badgeNumber, appointmentToken },
      { context: HttpContextFactory.create(this.spinnerContext) }
    );
  }
}
