/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AppointmentViewModel } from 'src/app/kiosk/models/appointment.viewmodel';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-confirm-appointment-details',
  templateUrl: './confirm-appointment-details.component.html',
  styleUrls: ['./confirm-appointment-details.component.scss'],
})
export class ConfirmAppointmentDetailsComponent {
  appointment: AppointmentViewModel;
  appointmentSlotStartDateTime = '';
  bookedServiceCodes: string[];
  showVehiclePartInfo: boolean;

  constructor(private readonly transloco: TranslocoService, private readonly wizard: WizardController) {
    this.appointment = this.wizard.context.getAppointment();

    this.bookedServiceCodes = [];
    const serviceTranslationSection = this.transloco.translateObject(
      'app.components.wizard.steps.confirm-appointment-details.base-services'
    );

    this.appointment.bookedServiceCodes.forEach((bsc) => this.bookedServiceCodes.push(serviceTranslationSection[bsc]));

    this.showVehiclePartInfo = this.appointment.showVehiclePartsInfo;

    const slotsDateTime = this.appointment.bookedSlots.map((x) => x.slotStartDateTime);

    // ordering booked slots by their starting date and time, then select the earliest
    this.appointmentSlotStartDateTime = slotsDateTime.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
  }

  scanAgain(): void {
    this.wizard.notifyScanQrCode();
  }

  wrongInformation(): void {
    this.wizard.notifyWrongAppointmentInformation();
  }

  confirm(): void {
    this.wizard.notifyConfirmComplete();
  }

  confirmedDisabled(): boolean {
    //   [disabled]="!appointment.driver.isAllowedForSLS || !appointment.driver.isAllowedForALS || !appointment.actualVehicles.isApproved || !appointment.actualVehicles.isComplete"
    if (this.appointment.hasSelfLoadingEnabled && !this.appointment.driver.isAllowedForSLS) {
      return true;
    } else if (this.appointment.hasAssistedLoadingEnabled && !this.appointment.driver.isAllowedForALS) {
      return true;
    } else if (!this.appointment.actualVehicles.isApproved) {
      return true;
    } else if (!this.appointment.actualVehicles.isComplete) {
      return true;
    }
    return false;
  }
}
