import { Observable, startWith, Subject, switchMap, timer } from 'rxjs';

export class Session {
  sessionTimer: Observable<number>;
  reset$ = new Subject();
  sessionTimedOut: Observable<number>;

  constructor(timeoutInterval: number) {
    this.sessionTimer = timer(timeoutInterval);
    this.sessionTimedOut = this.reset$.pipe(
      startWith(void 0),
      switchMap(() => timer(timeoutInterval))
    );
  }

  keepAlive(): void {
    this.reset$.next(0);
  }
}
