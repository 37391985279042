import { Component } from '@angular/core';
import { SettingsService } from 'src/app/kiosk/services/settings.service';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
})
export class SomethingWentWrongComponent {
  constructor(private readonly wizard: WizardController, private readonly settingService: SettingsService) {
    this.wizard.notifyInitial(this.settingService.navigationDelay);
  }
}
