<kiosk-wizard [step]="2">
  <div class="kiosk-hw100">
    <kiosk-template
      mainTitle="app.components.wizard.steps.scan-badge-already-active.title"
      subtitle="app.components.wizard.steps.scan-badge-already-active.sub-title"
      image="something-went-wrong"
    >
    </kiosk-template>
  </div>
</kiosk-wizard>
