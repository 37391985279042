// eslint-disable-next-line max-classes-per-file
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localenlBE from '@angular/common/locales/nl-BE';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlxCommonModule, FlxDatePipe } from '@fluxys/common';
import { FlxCoreModule } from '@fluxys/core';
import { FlxGSmartModule } from '@fluxys/gsmart';
import { FlxFormFieldWrapperModule } from '@fluxys/gsmart/form-field-wrapper';
import { FlxLayoutModule } from '@fluxys/gsmart/layout';
import { FlxLoadingSpinnerComponent, FlxPrimengModule } from '@fluxys/primeng';

import { TranslocoModule, TranslocoService, TRANSLOCO_MISSING_HANDLER } from '@ngneat/transloco';
import { HttpErrorInterceptor } from '@shared/interceptors/http.errorhandler.interceptor';
import { PrimeNgModule } from '@shared/modules/primeng/primeng.module';
import { MissingTranslationHandlerService } from '@shared/services/missing-translation-handler-service';
import { PrimeNGConfig } from 'primeng/api';

import { environment } from '../environments/environment';

import { AppInitializerHelper } from './app-initializer.helper';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WelcomCardComponent } from './kiosk/components/welcome/welcom-card/welcom-card.component';
import { WelcomeComponent } from './kiosk/components/welcome/welcome.component';
import { DevComponent } from './kiosk/components/wizard/dev/dev/dev.component';
import { NecklaceComponent } from './kiosk/components/wizard/necklace/necklace.component';
import { NecklaceCheckoutComponent } from './kiosk/components/wizard/necklace-checkout/necklace-checkout.component';
import { NextComponent } from './kiosk/components/wizard/next/next.component';
import { ReaonlyComponent } from './kiosk/components/wizard/reaonly/reaonly.component';
import { ScanBadgeErrorComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge-error/scan-badge-error.component';
import { ScanBadgeNoDriveLinkedComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge-no-drive-linked/scan-badge-no-drive-linked.component';
import { ScanBadgeSuccessComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge-success/scan-badge-success.component';
import { ScanBadgeComponent } from './kiosk/components/wizard/steps/check-in/1.scan-badge/scan-badge.component';
import { ScanQrCodeErrorComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code-error/scan-qr-code-error.component';
import { ScanQrCodeNoAppointmentScheduledComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code-no-appointment-scheduled/scan-qr-code-no-appointment-scheduled.component';
import { ScanQrCodeSuccessComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code-success/scan-qr-code-success.component';
import { ScanQrCodeComponent } from './kiosk/components/wizard/steps/check-in/2.scan-qr-code/scan-qr-code.component';
import { ConfirmAppointmentDetailsComponent } from './kiosk/components/wizard/steps/check-in/3.confirm-appointment-details/confirm-appointment-details.component';
import { ConfirmAppointmentWrongDetailsComponent } from './kiosk/components/wizard/steps/check-in/3.confirm-appointment-details/confirm-appointment-wrong-details/confirm-appointment-wrong-details.component';
import { CheckinCompleteComponent } from './kiosk/components/wizard/steps/check-in/4.checkin-complete/checkin-complete.component';
import { ScanBadgeAlreadyActiveComponent } from './kiosk/components/wizard/steps/check-out/1.scan-badge/scan-badge-already-active/scan-badge-already-active.component';
import { PrintBolComponent } from './kiosk/components/wizard/steps/check-out/2.print-bol/print-bol.component';
import { ConfirmPrintComponent } from './kiosk/components/wizard/steps/check-out/3.confirm-print/confirm-print.component';
import { CheckoutCompleteComponent } from './kiosk/components/wizard/steps/check-out/4.checkout-complete/checkout-complete.component';
import { PrintingSpinnerComponent } from './kiosk/components/wizard/steps/check-out/printing-spinner/printing-spinner.component';
import { SomethingWentWrongComponent } from './kiosk/components/wizard/steps/something-went-wrong/something-went-wrong.component';
import { TemplateComponent } from './kiosk/components/wizard/template/template.component';
import { WizardComponent } from './kiosk/components/wizard/wizard.component';

const COMPONENTS = [
  AppComponent,
  CheckinCompleteComponent,
  CheckoutCompleteComponent,
  ConfirmAppointmentDetailsComponent,
  ConfirmAppointmentWrongDetailsComponent,
  ConfirmPrintComponent,
  DevComponent,
  NecklaceComponent,
  NecklaceCheckoutComponent,
  NextComponent,
  ScanBadgeComponent,
  ScanBadgeSuccessComponent,
  ScanBadgeErrorComponent,
  ScanQrCodeNoAppointmentScheduledComponent,
  ScanBadgeNoDriveLinkedComponent,
  ScanQrCodeComponent,
  ScanQrCodeSuccessComponent,
  ScanQrCodeErrorComponent,
  SomethingWentWrongComponent,
  ScanBadgeAlreadyActiveComponent,
  TemplateComponent,
  PrintBolComponent,
  WelcomeComponent,
  WelcomCardComponent,
  WizardComponent,
  ReaonlyComponent,
  PrintingSpinnerComponent,
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    FlxCommonModule.forRoot({ availableLanguages: ['en', 'nl', 'de', 'fr'], defaultLanguage: 'en' }),
    FlxCoreModule.forRoot({ gitHash: environment.version.sha }),

    FlxPrimengModule.forRoot(),
    FlxGSmartModule.forRoot(),
    FlxLayoutModule.forRoot(),
    FlxDatePipe,
    FlxFormFieldWrapperModule,
    FlxLoadingSpinnerComponent,
    FormsModule,
    HttpClientModule,
    PrimeNgModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],

  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializerHelper.createFactory,
      deps: [PrimeNGConfig, TranslocoService],
      multi: true,
    },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingTranslationHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
})
export class AppModule {
  constructor() {
    registerLocaleData(localenlBE);
  }
}
