import { Component } from '@angular/core';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-scan-qr-code-no-appointment-scheduled',
  templateUrl: './scan-qr-code-no-appointment-scheduled.component.html',
})
export class ScanQrCodeNoAppointmentScheduledComponent {
  constructor(private readonly wizard: WizardController) {}
  navigate(): void {
    this.wizard.notifyScanQrCode();
  }
}
