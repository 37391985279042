import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

import { WizardController } from '../../services/wizard-controller.service';

@Component({
  selector: 'kiosk-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent {
  constructor(private readonly transloco: TranslocoService, private readonly wizard: WizardController) {}

  selectActiveLanguage(locale: string): void {
    this.transloco.setActiveLang(locale);
    this.wizard.notifyLanguageSelected();
  }
}
