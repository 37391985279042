import { Component } from '@angular/core';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-scan-badge-success',
  templateUrl: './scan-badge-success.component.html',
  styleUrls: ['./scan-badge-success.component.scss'],
})
export class ScanBadgeSuccessComponent {
  driverName: string | undefined;
  constructor(private readonly wizard: WizardController) {
    this.driverName = this.wizard.context.getBadge().badgeLoan?.driverName;
  }

  navigate(): void {
    this.wizard.notifyScanBadgeSuccess();
  }

  navigateNo(): void {
    this.wizard.notifyScanBadgeError();
  }
}
