import { DOCUMENT, PlatformLocation } from '@angular/common';
import { Component, Inject, Renderer2 } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ROUTETRANSISIONANIMATION } from '@shared/animations/route.transition-animation';

@UntilDestroy()
@Component({
  selector: 'kiosk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ROUTETRANSISIONANIMATION],
})
export class AppComponent {
  constructor(
    private readonly router: Router,
    private readonly platformLocation: PlatformLocation,
    @Inject(DOCUMENT) document: Document,
    renderer: Renderer2
  ) {
    if (this.isLocalhost()) {
      renderer.addClass(document.body, 'env-loc');
    }
    // we make sure that after a hot reload, we navigate to the start, except for the support page
    if (!window.location.href.endsWith('support')) {
      void router.navigate(['']);
    }
  }

  getRouterState(outlet: RouterOutlet): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/prefer-optional-chain, @typescript-eslint/no-unnecessary-condition
    return outlet && outlet.activatedRouteData.state;
  }

  // ref: https://stackoverflow.com/a/57949518
  private isLocalhost(): boolean {
    const hostName = this.platformLocation.hostname;

    return (
      hostName === '127.0.0.1' ||
      hostName === 'localhost' ||
      // [::1] is the IPv6 localhost address.
      hostName === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      !!hostName.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
    );
  }
}
