<div class="c-necklace kiosk-appear">
  <div class="c-necklace-ball-holder">
    <div class="c-necklace-ball" [ngClass]="{'c-necklace-ball-active' : step <=2,'c-necklace-ball-success':step > 2}">1</div>
    <span class="c-necklace-ball-label">{{ 'app.components.wizard.necklace.scan-badge' | transloco }}</span>
  </div>

  <div class="c-necklace-connector" [ngClass]="{'c-necklace-connector-success': step >2 }"></div>

  <div class="c-necklace-ball-holder">
    <div class="c-necklace-ball" [ngClass]="{'c-necklace-ball-active' : step ===3 ||step===4,'c-necklace-ball-success':step > 4}">2</div>
    <span class="c-necklace-ball-label">{{ 'app.components.wizard.necklace.scan-qr-code' | transloco }}</span>
  </div>

  <div class="c-necklace-connector" [ngClass]="{'c-necklace-connector-success': step >4 }"></div>

  <div class="c-necklace-ball-holder">
    <div class="c-necklace-ball" [ngClass]="{'c-necklace-ball-active' : step ===5,'c-necklace-ball-success':step > 5}">3</div>
    <span class="c-necklace-ball-label">{{ 'app.components.wizard.necklace.confirm-details' | transloco }}</span>
  </div>
</div>
