import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kiosk-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit {
  @Input() defaultTemplate = true;
  @Input() mainTitle = '';
  @Input() subtitle = '';
  @Input() comment = '';
  @Input() warning = '';
  @Input() svg = '';
  @Input() image = '';

  picturePath = '';

  ngOnInit(): void {
    this.picturePath = this.svg ? `assets/graphics/svg/${this.svg}.svg` : `assets/graphics/png/${this.image}.png`;
  }
}
