<kiosk-wizard [step]="2">
  <div class="kiosk-hw100">
    <kiosk-template
      mainTitle="app.components.wizard.steps.scan-qr-code-error.title"
      subtitle="app.components.wizard.steps.scan-qr-code-error.sub-title"
      image="something-went-wrong"
    >
    </kiosk-template>
    <div class="kiosk-bottom-button-container">
      <button
        pButton
        pRipple
        type="button"
        label="{{'app.components.wizard.steps.scan-qr-code-error.scan-again' | transloco}}"
        (click)="navigate()"
        class="p-button-secondary btn-xl"
      ></button>
    </div>
  </div>
</kiosk-wizard>
