<kiosk-wizard [step]="5">
  <div class="kiosk-hw100">
    <h1 class="text-center">{{ 'app.components.wizard.steps.confirm-print.title' | transloco }}</h1>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-around">
      <button pButton (click)="onPrintBolButtonClicked()" label="Print" class="btn-xl"></button>
      <button pButton (click)="onFinishButtonClicked()" label="Finish" class="btn-xl"></button>
    </div>
  </div>
</kiosk-wizard>
