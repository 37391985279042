<kiosk-wizard [step]="5">
  <div class="kiosk-hw100">
    <div class="container">
      <!--general-->
      <h1 transloco="app.components.wizard.steps.confirm-appointment-details.title"></h1>
      <div class="row">
        <div class="col-3 d-flex flex-column">
          <h4 class="flx-header--subtitle" transloco="app.components.wizard.steps.confirm-appointment-details.general"></h4>
        </div>
        <div class="col-9 d-flex flex-column">
          <div class="row">
            <div class="col-6">
              <kiosk-readonly
                label="app.components.wizard.steps.confirm-appointment-details.date"
                [value]="appointmentSlotStartDateTime | flxDate: 'dd.MM.yyyy'"
              ></kiosk-readonly>
            </div>
            <div class="col-6">
              <kiosk-readonly
                label="app.components.wizard.steps.confirm-appointment-details.driver"
                [value]="appointment.driver.name"
              ></kiosk-readonly>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <kiosk-readonly
                label="app.components.wizard.steps.confirm-appointment-details.time"
                [value]="appointmentSlotStartDateTime | flxDate:'HH:mm'"
              ></kiosk-readonly>
              <kiosk-readonly
                class="c-confirm-appointment-margin-top-18"
                label="app.components.wizard.steps.confirm-appointment-details.identifier"
                [value]="appointment.referenceCode"
              ></kiosk-readonly>
            </div>
            <div class="col-6">
              <kiosk-readonly
                label="app.components.wizard.steps.confirm-appointment-details.services"
                [value]="bookedServiceCodes"
              ></kiosk-readonly>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="kiosk-error-message">
            <span *ngIf="!appointment.driver.isAllowedForSLS && appointment.hasSelfLoadingEnabled">
              {{'app.components.wizard.steps.confirm-appointment-details.errors.driver-not-authorized-for-SLS'|transloco}}
            </span>
            <span *ngIf="!appointment.driver.isAllowedForALS && appointment.hasAssistedLoadingEnabled">
              {{'app.components.wizard.steps.confirm-appointment-details.errors.driver-not-authorized-for-ALS'|transloco}}
            </span>
          </p>
        </div>
      </div>
      <!--vehicleparts-->
      <div
        class="row"
        *ngIf="this.showVehiclePartInfo
      "
      >
        <div class="col-3 d-flex flex-column">
          <h4 class="flx-header--subtitle" transloco="app.components.wizard.steps.confirm-appointment-details.actual-vehicle-parts"></h4>
        </div>
        <div class="col-9 d-flex flex-column">
          <div class="row">
            <div class="col-6 d-flex flex-column">
              <kiosk-readonly
                *ngIf="appointment.actualVehicles.tractor"
                label="app.components.wizard.steps.confirm-appointment-details.tractor"
                [value]="appointment.actualVehicles.tractor.reference"
              ></kiosk-readonly>

              <kiosk-readonly
                *ngIf="appointment.actualVehicles.roadTanker"
                label="app.components.wizard.steps.confirm-appointment-details.road-tanker"
                [value]="appointment.actualVehicles.roadTanker.reference"
                class="c-confirm-appointment-margin-top-18"
              ></kiosk-readonly>

              <kiosk-readonly
                *ngIf="appointment.actualVehicles.chassis"
                label="app.components.wizard.steps.confirm-appointment-details.chassis"
                [value]="appointment.actualVehicles.chassis.reference"
                class="c-confirm-appointment-margin-top-18"
              ></kiosk-readonly>

              <kiosk-readonly
                *ngIf="appointment.actualVehicles.lngContainer"
                label="app.components.wizard.steps.confirm-appointment-details.lngContainer"
                [value]="appointment.actualVehicles.lngContainer.reference"
                class="c-confirm-appointment-margin-top-18"
              ></kiosk-readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kiosk-bottom-button-container">
    <button
      pButton
      pRipple
      type="button"
      label="{{'app.components.wizard.steps.confirm-appointment-details.scan-again' | transloco}}"
      (click)="scanAgain()"
      class="p-button-secondary btn-xl"
    ></button>
    <button
      pButton
      pRipple
      type="button"
      label="{{'app.components.wizard.steps.confirm-appointment-details.wrong-information' | transloco}}"
      (click)="wrongInformation()"
      class="p-button-danger btn-xl"
    ></button>
    <button
      [disabled]="this.confirmedDisabled()"
      pButton
      pRipple
      type="button"
      label="{{'app.components.wizard.steps.confirm-appointment-details.confirm' | transloco}}"
      (click)="confirm()"
      class="btn-xl"
    ></button>
  </div>
</kiosk-wizard>
