import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const ROUTETRANSISIONANIMATION = trigger('routerTransition', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
      query(':enter', [style({ opacity: 0 }), animate('0.45s ease-in-out', style({ opacity: 1 }))], { optional: true }),
      query(':leave', [style({ transform: 'translateX(0%)' }), animate('0.45s ease-in-out', style({ transform: 'translateX(-100%)' }))], {
        optional: true,
      }),
    ]),
  ]),
]);
