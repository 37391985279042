import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpContextFactory } from '@shared/utils/http.context.factory';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DebugService {
  private readonly baseUrl = '/api/kiosk/debug';
  private readonly spinnerContext = 'kiosk';

  constructor(private readonly http: HttpClient) {}

  getAppointmentToken(appointmentId: number): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/get-appointment-token/${appointmentId}`, {
      context: HttpContextFactory.create(this.spinnerContext),
    });
  }
}
