/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, EMPTY, Observable, Subscription, take, timer } from 'rxjs';
import { KIOSK_ROUTES } from 'src/app-routing';

import { Session } from '../../shared/session/session';
import { AppointmentViewModel } from '../models/appointment.viewmodel';
import { AppointmentStatus } from '../models/enums';
import { KioskContext } from '../models/kiosk-context';

import { KioskService } from './kiosk.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class WizardController {
  context: KioskContext = new KioskContext();
  private readonly session: Session;
  private delayedNavTimer: Observable<number> | undefined;
  private delayedNavTimerSub: Subscription | undefined;
  private printFrame: HTMLIFrameElement | undefined;

  constructor(
    private readonly router: Router,
    private readonly kioskService: KioskService,
    private readonly settingsService: SettingsService
  ) {
    this.session = new Session(this.settingsService.sessionTimeOut);
    this.session.sessionTimedOut.subscribe(() => this.resetSession());
    this.notifyInitial();
  }

  get flowIsCheckIn(): boolean | undefined {
    return this.context.flowIsCheckin;
  }

  notifyInitial(timeout?: number): void {
    this.context = new KioskContext();
    this.delayedNavigation(KIOSK_ROUTES.initial, timeout);
  }

  notifyLanguageSelected(timeout?: number): void {
    this.notifyScanBadge(timeout);
  }

  notifySomethingWentWrong(error: unknown): Observable<never> {
    this.delayedNavigation(KIOSK_ROUTES.somethingWentWrong, 0);
    return EMPTY;
  }

  // CHECK IN
  notifyScanBadge(timeout?: number): void {
    this.delayedNavigation(KIOSK_ROUTES.checkin.scanBadge, timeout);
  }

  notifyScanBadgeError(timeout?: number): void {
    this.delayedNavigation(KIOSK_ROUTES.checkin.scanBadgeError);
  }

  notifyScanBadgeComplete(token: string | undefined, timeout?: number): void {
    if (token) {
      this.kioskService
        .getBadge(token)
        .pipe(take(1))
        .subscribe(
          (badge) => {
            if (!badge.badgeExists) {
              this.delayedNavigation(KIOSK_ROUTES.checkin.scanBadgeError);
              return;
            }

            if (!badge.hasBadgeLoan) {
              this.delayedNavigation(KIOSK_ROUTES.checkin.scanBadgeNoDriverLinked);
              return;
            }
            this.context.addBadge(badge);
            if (badge.badgeLoan?.hasAppointment) {
              this.context.addAppointment(badge.badgeLoan.appointment);
            }

            this.delayedNavigation(KIOSK_ROUTES.checkin.scanBadgeSuccess, timeout);
          },
          (error) => this.notifySomethingWentWrong(error)
        );
    } else {
      this.delayedNavigation(KIOSK_ROUTES.checkin.scanBadgeError);
    }
  }

  notifyScanQrCode(timeout?: number): void {
    this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCode, timeout);
  }

  notifyScanQrCodeComplete(appointmentToken: string | undefined, timeout?: number): void {
    if (appointmentToken === undefined) {
      this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCodeError);
      return;
    }
    if (
      (appointmentToken && !this.context.hasAppointment) ||
      (this.context.hasAppointment && this.context.appointmentStatus === AppointmentStatus.Confirmed) ||
      (this.context.hasAppointment && this.context.appointmentStatus === AppointmentStatus.Arrived)
    ) {
      this.context.addAppointmentToken(appointmentToken);
      this.kioskService
        .getAppointment(appointmentToken, this.context.getBadge().badgeLoan?.driverId)
        .pipe(
          take(1),
          catchError((er) => this.notifySomethingWentWrong(er))
        )
        .subscribe((appointment: AppointmentViewModel) => {
          if (!appointment.appointmentExists) {
            this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCodeError);
            return;
          }

          if (
            !(appointment.operationalStatus === AppointmentStatus.Confirmed || appointment.operationalStatus === AppointmentStatus.Arrived)
          ) {
            this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCodeNoAppointment);
            return;
          }

          if (appointment.hasErrors) {
            this.context.addAppointment(appointment);
            this.delayedNavigation(KIOSK_ROUTES.checkin.confirmWrongDetails);
            return;
          }

          if (appointment.isAllowed) {
            // if status of appointment is confirmed, the kiosk set the appointment to Arrived
            this.context.addAppointment(appointment);
            this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCodeSuccess, timeout);
          } else {
            this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCodeNoAppointment);
          }
        });
    } else {
      this.delayedNavigation(KIOSK_ROUTES.checkin.scanQrCodeNoAppointment);
    }
  }

  notifyConfirmDetails(timeout?: number): void {
    const appointment = this.context.getAppointment();
    const token = this.context.getAppointmentToken();
    if (
      (appointment.operationalStatus === AppointmentStatus.Confirmed || appointment.operationalStatus === AppointmentStatus.Arrived) &&
      token
    ) {
      this.kioskService
        .setAppointmentStatusArrived(token)
        .pipe(catchError((er) => this.notifySomethingWentWrong(er)))
        .subscribe(() => this.delayedNavigation(KIOSK_ROUTES.checkin.confirm, timeout));
    } else {
      alert('not implemented... awaiting analysis.');
    }
  }

  notifyWrongAppointmentInformation(timeout?: number): void {
    this.context.addErrorCode('manuallydisapproved');
    this.delayedNavigation(KIOSK_ROUTES.checkin.confirmWrongDetails);
  }

  notifyCheckinComplete(timeout?: number): void {
    this.notifyInitial(timeout);
  }

  // TODO: delegate the orchestration to the Kiosk gateway
  notifyConfirmComplete(timeout?: number): void {
    const token = this.context.getAppointmentToken();
    const badgeNumber = this.context.getBadge().badgeNumber;
    this.kioskService
      .confirmDetails(badgeNumber, token)
      .pipe(catchError((er) => this.notifySomethingWentWrong(er)))
      .subscribe((_) => this.delayedNavigation(KIOSK_ROUTES.checkin.checkinComplete));
  }

  // TWILIGHT ZONE
  notifyScanBadgeSuccess(timeout?: number): void {
    if (this.flowIsCheckIn) {
      this.notifyScanQrCode();
    } else {
      // From here, we know there is an appointment linked to the badge
      // and its status is NOT Arrived
      switch (this.context.appointmentStatus) {
        case AppointmentStatus.Operational:
          this.notifyAppointmentAlreadyActive();
          break;
        case AppointmentStatus.LoadingCompleted:
        case AppointmentStatus.Completed:
          this.notifyPrintBillOfLading();
          break;
        default:
          this.notifySomethingWentWrong(EMPTY);
          break;
      }
    }
  }

  // CHECKOUT

  /**
   *
   * @param timeout
   */

  notifyAppointmentAlreadyActive(timeout?: number): void {
    this.delayedNavigation(KIOSK_ROUTES.checkout.scanBadgeAlreadyActive, timeout);
  }

  notifyPrintBillOfLading(timeout?: number): void {
    this.delayedNavigation(KIOSK_ROUTES.checkout.printBol, timeout);
  }

  /**
   * Last method called by the step 'Print Bill of Lading'
   * @param timeout
   */
  notifyPrintBillOfLadingComplete(timeout?: number): void {
    if (this.context.billOfLadingId) {
      this.kioskService.getAppointmentBillOfLading(this.context.billOfLadingId).subscribe(
        (responseBlob) => {
          if (responseBlob.body) {
            this.context.isPrinting = true;
            const docurl = URL.createObjectURL(responseBlob.body);
            try {
              this.silentPrint(docurl);
            } catch (ex) {
              console.log(ex);
              throw ex;
            }

            timer(this.settingsService.printerShield).subscribe((p) => {
              this.context.isPrinting = false;
              this.delayedNavigation(KIOSK_ROUTES.checkout.confirmPrint, 1000);
            });
          }
        },
        (error) => this.notifySomethingWentWrong(error)
      );
    } else {
      this.notifySomethingWentWrong(EMPTY);
    }
  }

  silentPrint(docUrl: any) {
    var iframe = this.printFrame;
    if (!this.printFrame) {
      iframe = this.printFrame = document.createElement('iframe');
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
      iframe.onload = () => {
        setTimeout(() => {
          iframe?.focus();
          iframe?.contentWindow?.print();
        }, 1);
      };
    }

    if (iframe) {
      iframe.src = docUrl;
    }
  }
  /**
   * For debug purposes only
   * Allows to see the Bill of Lading in an iFrame
   * or to read the error message in the console
   * @param bolId
   */
  notifyDebugPrintBillOfLadingComplete(bolId: number): void {
    if (bolId) {
      this.kioskService.getAppointmentBillOfLading(bolId).subscribe(
        (responseBlob) => {
          if (responseBlob.body) {
            const iframe = document.createElement('iframe');
            const documentObjectUrl = URL.createObjectURL(responseBlob.body);

            iframe.src = documentObjectUrl;
            document.body.appendChild(iframe);
            // eslint-disable-next-line no-console
            console.log(`printing bill of lading with id #${bolId}...`);
          }

          this.delayedNavigation(KIOSK_ROUTES.checkout.confirmPrint, 10000);
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      );
    } else {
      this.notifySomethingWentWrong(EMPTY);
    }
  }

  notifyConfirmPrintComplete(timeout?: number): void {
    const appointment = this.context.getAppointment();
    const badge = this.context.getBadge();
    if (
      appointment.operationalStatus === AppointmentStatus.LoadingCompleted ||
      appointment.operationalStatus === AppointmentStatus.Completed
    ) {
      this.kioskService.completeCheckout(badge.badgeNumber).subscribe(
        () => this.delayedNavigation(KIOSK_ROUTES.checkout.checkoutComplete, timeout),
        (err) => this.notifySomethingWentWrong(err)
      );
    } else {
      console.log('not implement...yet');
    }
  }

  notifyCheckoutCompleted(timeout?: number): void {
    this.notifyInitial(timeout);
  }

  private delayedNavigation(path: string, timeout?: number): void {
    this.session.keepAlive();

    const fullPath = `/${path}`;

    if (!timeout) {
      if (this.delayedNavTimerSub) {
        this.delayedNavTimerSub.unsubscribe();
      }
      void this.router.navigate([fullPath]);
    } else {
      this.delayedNavTimer = timer(timeout);
      this.delayedNavTimerSub = this.delayedNavTimer.subscribe(() => void this.router.navigate([fullPath]));
    }
  }

  private resetSession(): void {
    if (this.router.url !== '/welcome') {
      this.notifyInitial();
    }
  }
}
