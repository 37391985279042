import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kiosk-readonly',
  templateUrl: './reaonly.component.html',
  styleUrls: ['./reaonly.component.scss'],
})
export class ReaonlyComponent implements OnInit {
  @Input() label: string | undefined;
  @Input() value: string[] | string | undefined;
  values: string[] | undefined;

  ngOnInit(): void {
    if (Array.isArray(this.value)) {
      this.values = [...this.value];
    } else if (this.value !== undefined) {
      this.values = [this.value];
    }
  }
}
