<kiosk-wizard [step]="0">
  <div class="kiosk-hw100 d-flex flex-column justify-content-between">
    <div class="h-100">
      <kiosk-template mainTitle="app.components.wizard.steps.scan-badge.title" image="scan-badge"></kiosk-template>
    </div>


    <!-- eslint-disable-next-line @angular-eslint/template/no-autofocus -->
    <input autofocus type="text" #scanInput (blur)="onBlur($event)" class="hidden-input" />
  </div>
</kiosk-wizard>