import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@UntilDestroy()
@Component({
  selector: 'kiosk-print-bol',
  templateUrl: './print-bol.component.html',
})
export class PrintBolComponent {
  constructor(private readonly wizard: WizardController) {}

  onPrintBolButtonClicked(): void {
    this.wizard.notifyPrintBillOfLadingComplete();
  }
}
