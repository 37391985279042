import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlxLoggerService } from '@fluxys/core';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private readonly loggerService: FlxLoggerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((e: HttpErrorResponse) => this.handle(e)));
  }

  private handle(error: HttpErrorResponse): Observable<never> {
    if (this.ignoreError(error)) return new Observable<never>();
    return throwError(() => {
      this.handleError(error);
      return new Error(error.message);
    });
  }

  private ignoreError(error: HttpErrorResponse): boolean {
    // todo: this should be removed when FlxLayout can go full screen
    if (error.url?.startsWith('https://clientgateway-gsmart-web-portal')) {
      return true;
    }
    return false;
  }

  private handleError(error: HttpErrorResponse): void {
    this.loggerService.error('An unexpected error occurred:', error);
  }
}
