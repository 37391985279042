import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'kiosk-next',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss'],
})
export class NextComponent {
  @Output() readonly nextSelected = new EventEmitter<string>();
  @Input() nextEnabled = true;

  selectNext(): void {
    this.nextSelected.emit();
  }
}
