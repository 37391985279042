import { Component } from '@angular/core';
import { SettingsService } from 'src/app/kiosk/services/settings.service';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-scan-badge-no-drive-linked',
  templateUrl: './scan-badge-no-drive-linked.component.html',
})
export class ScanBadgeNoDriveLinkedComponent {
  constructor(private readonly wizard: WizardController, private readonly settingsService: SettingsService) {
    this.wizard.notifyInitial(this.settingsService.navigationDelay);
  }
}
