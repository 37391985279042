<ng-container *ngIf="defaultTemplate">
  <div class="kiosk-center-text mb-4">
    <h1 *ngIf="mainTitle" class="kiosk-sub-title" [transloco]="mainTitle"></h1>
    <h2 *ngIf="subtitle" class="kiosk-sub-title" [transloco]="subtitle"></h2>
  </div>

  <div class="kiosk-center-image-container" *ngIf="svg || image">
    <img [src]="picturePath" class="kiosk-center-image" alt="explaining pictogram" />
  </div>
  <h2 *ngIf="warning" class="kiosk-warning mt-5 text-danger" [transloco]="warning"></h2>
</ng-container>
<ng-content></ng-content>
