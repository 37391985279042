import { Component, Input } from '@angular/core';

import { NecklaceComponent } from '../necklace/necklace.component';

@Component({
  selector: 'kiosk-necklace-checkout',
  templateUrl: './necklace-checkout.component.html',
  styleUrls: ['./necklace-checkout.component.scss'],
})
export class NecklaceCheckoutComponent extends NecklaceComponent {
  @Input() step = 0;
}
