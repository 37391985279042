/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-console */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, fromEvent, take } from 'rxjs';
import { ScannerService } from 'src/app/kiosk/services/scanner.service';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@UntilDestroy()
@Component({
  selector: 'kiosk-scan-badge',
  templateUrl: './scan-badge.component.html',
})
export class ScanBadgeComponent implements OnInit {
  @ViewChild('scanInput', { static: true })
  scanInput!: ElementRef;

  constructor(private readonly wizard: WizardController, private readonly scanner: ScannerService) {
    this.scanner.onScanBadgeSuccess.pipe(untilDestroyed(this)).subscribe((badgeNumber) => {
      this.wizard.notifyScanBadgeComplete(badgeNumber);
    });

    this.scanner.onScanBadgeError.pipe(untilDestroyed(this)).subscribe((badgeNumber) => {
      this.wizard.notifyScanBadgeComplete(undefined);
    });
  }

  ngOnInit(): void {
    fromEvent(this.scanInput.nativeElement, 'keyup')
      .pipe(debounceTime(500), take(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((_) => {
        if (this.scanInput.nativeElement) {
          console.log(this.scanInput.nativeElement.value);
          const token = this.scanInput.nativeElement.value as string;
          this.scanner.scanBadgeSuccess(token);
        }
      });
    this.scanInput.nativeElement.focus();
  }

  onBlur(event: unknown): void {
    if (!this.wizard.context.isDebugMode) {
      this.scanInput.nativeElement.focus();
    }
  }
}
