<kiosk-wizard [step]="2">
  <div class="kiosk-hw100">
    <kiosk-template
      mainTitle="app.components.wizard.steps.confirm-appointment-wrong-details.title"
      subtitle="app.components.wizard.steps.confirm-appointment-wrong-details.sub-title"
      image="something-went-wrong"
    >
    </kiosk-template>
    <ul *ngIf="errors && errors.length >0" class="c-app-wrong-details-errors">
      <li *ngFor="let error of errors">{{error}}</li>
    </ul>
    <div class="kiosk-bottom-button-container">
      <button
        pButton
        pRipple
        type="button"
        label="{{'app.components.wizard.steps.confirm-appointment-wrong-details.scan-again' | transloco}}"
        (click)="navigate()"
        class="p-button-secondary btn-xl"
      ></button>
    </div>
  </div>
</kiosk-wizard>
