import { Component, OnInit } from '@angular/core';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-checkin-complete',
  templateUrl: './checkin-complete.component.html',
  styleUrls: ['./checkin-complete.component.scss'],
})
export class CheckinCompleteComponent implements OnInit {
  constructor(private readonly wizard: WizardController) {}

  ngOnInit(): void {
    this.wizard.notifyCheckinComplete(10000);
  }

  navigate(): void {
    this.wizard.notifyCheckinComplete();
  }
}
