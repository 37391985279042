<div class="c-next">
  <button
    [disabled]="!nextEnabled"
    pButton
    pRipple
    type="button"
    label="{{'app.components.wizard.next.next' | transloco}}"
    (click)="selectNext()"
    class="p-button-secondary btn-xl"
  ></button>
</div>
