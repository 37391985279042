import { Injectable } from '@angular/core';
import { FlxLoggerService } from '@fluxys/core';
import { TranslocoConfig, TranslocoMissingHandler } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';

@Injectable()
export class MissingTranslationHandlerService implements TranslocoMissingHandler {
  constructor(private readonly logger: FlxLoggerService, private readonly messageService: MessageService) {}
  handle(key: string, config: TranslocoConfig): string {
    if (!config.prodMode) {
      /* this.messageService.add({
        severity: 'warn',
        summary: `Missing translation key: ${key}`,
        detail: 'Hey developer, open the developer tools and check what is happening',
        sticky: false,
        closable: true,
      });*/
      const msg = `Missing translation for '${key}', put a breakpoint here`;
      this.logger.warn(`%c ${msg}`, 'font-size: 12px; color: red');
    }

    return key;
  }
}
