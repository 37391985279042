import { Component } from '@angular/core';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@Component({
  selector: 'kiosk-confirm-print',
  templateUrl: './confirm-print.component.html',
})
export class ConfirmPrintComponent {
  constructor(private readonly wizard: WizardController) {}

  onPrintBolButtonClicked(): void {
    this.wizard.notifyPrintBillOfLadingComplete();
  }

  onFinishButtonClicked(): void {
    this.wizard.notifyConfirmPrintComplete();
  }
}
