/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable no-console */
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, debounceTime, distinctUntilChanged, take } from 'rxjs';
import { ScannerService } from 'src/app/kiosk/services/scanner.service';
import { WizardController } from 'src/app/kiosk/services/wizard-controller.service';

@UntilDestroy()
@Component({
  selector: 'kiosk-scan-qr-code',
  templateUrl: './scan-qr-code.component.html',
})
export class ScanQrCodeComponent implements OnInit {
  @ViewChild('scanInput', { static: true })
  scanInput!: ElementRef;
  appointmentToken: string | undefined = undefined;

  constructor(private readonly wizard: WizardController, private readonly scanner: ScannerService) {
    this.scanner.onScanQrCodeSucess.pipe(untilDestroyed(this)).subscribe((scanResult) => {
      this.appointmentToken = scanResult;
      this.wizard.notifyScanQrCodeComplete(this.appointmentToken);
    });

    this.scanner.onScanQrCodeError.pipe(untilDestroyed(this)).subscribe(() => {
      this.wizard.notifyScanQrCodeComplete(undefined);
    });
  }

  ngOnInit(): void {
    fromEvent(this.scanInput.nativeElement, 'keyup')
      .pipe(debounceTime(500), take(1), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((_) => {
        if (this.scanInput.nativeElement) {
          const token = this.scanInput.nativeElement?.value as string;
          this.scanner.scanQrCodeSuccess(token);
        }
      });
    this.scanInput.nativeElement.focus();
  }

  onBlur(event: unknown): void {
    if (!this.wizard.context.isDebugMode) {
      this.scanInput.nativeElement.focus();
    }
  }
}
